.logo {
  img {
    width: 120px;
  }
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* CSS */
.sing-in-button {
  align-items: center;
  background-color: #fff;
  border: 2px solid #ff6300;
  border-radius: 4px;
  box-sizing: border-box;
  color: #000;
  cursor: pointer;
  display: inline-flex;
  fill: #4CAF50;
  /* font-family: Inter,sans-serif; */
  font-size: 16px;
  font-weight: 600;
  height: 48px;
  justify-content: center;
  /* letter-spacing: -.8px; */
  line-height: 24px;
  min-width: 140px;
  outline: 0;
  padding: 0 17px;
  text-align: center;
  text-decoration: none;
  transition: all .3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.sing-in-button:focus {
  color: #171e29;
  background-color: #fff;
}

.sing-in-button:hover {
  border-color: #06f;
  color: #06f;
  fill: #06f;
  background-color: #fff;
}

.sing-in-button:active {
  border-color: #06f;
  color: #06f;
  fill: #06f;
  background-color: #fff;
}

@media (min-width: 768px) {
  .sing-in-button {
    min-width: 170px;
  }
}

.secondary-button {
  align-items: center;
  background-color: #fff;
  border: 2px solid #ff6300;
  border-radius: 4px;
  box-sizing: border-box;
  color: #fff;
  background-color: #ff6300;
  cursor: pointer;
  display: inline-flex;
  fill: #4CAF50;
  /* font-family: Inter,sans-serif; */
  font-size: 16px;
  font-weight: 600;
  height: 48px;
  justify-content: center;
  /* letter-spacing: -.8px; */
  line-height: 24px;
  min-width: 140px;
  outline: 0;
  padding: 0 17px;
  text-align: center;
  text-decoration: none;
  transition: all .3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.secondary-button:focus {
  color: #171e29;
  background-color: #fff;
}

.secondary-button:hover {
  border-color: #06f;
  color: #06f;
  fill: #06f;
  background-color: #fff;
}

.secondary-button:active {
  border-color: #06f;
  color: #06f;
  fill: #06f;
  background-color: #fff;
}

@media (min-width: 768px) {
  .secondary-button {
    min-width: 170px;
  }
}

.form-control, .form-select {
  font-weight: 500;
}

.page-container {
  max-width: 1024px;
}