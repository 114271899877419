html,
body {
  background-color: #fff;
  margin: 0;
  padding: 0;
}

.wait-list {
  padding: 2rem;
  border-radius: 8px;

  h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #5e4dcd;
    padding: 0;
    font-weight: bold;
  }

  p {
    margin-bottom: 1rem;
    color: #3c3852;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    input {
      min-height: 50px;
      border-radius: 4px;
      padding: 0 1rem;
      color: #3c3852;
      font-size: 15px;
      border: 1px solid #5e4dcd;
      background-color: transparent;

      &:focus,
      &:focus-visible {
        border-color: #3898EC;
        outline: none;
      }
    }

    button {
      min-height: 50px;
      padding: .5em 1em;
      border: none;
      border-radius: 6px;
      background-color: #5e4dcd;
      color: #fff;
      font-size: 15px;
      cursor: pointer;
      transition: background-color .3s ease-in-out;

      &:hover {
        background-color: #5e5dcd;
      }

      &:disabled {
        background-color: #ccc;
        cursor: not-allowed;
      }
    }
  }

  .message-container {
    height: 20px;
    margin-top: 5px;
    margin-bottom: 1rem;

    p {
      margin: 0;
      padding: 0;
    }
  }

  .success-message {
    color: #6cc070;
  }

  .error-message {
    color: #ff6633;
  }

  .powered-by {
    background: linear-gradient(90deg, #ff6300, #ff6300);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #000;
    margin-top: 10px;
    display: block;
    text-align: right;
    font-size: 10px;

    a {
      text-decoration: none;
      strong {
        letter-spacing: 0.8px;
        font-size: 12px;
      }
    }
  }
}